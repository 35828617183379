/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useRef } from "react";

// next
import dynamic from "next/dynamic";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// PRO
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// images
import joeKotz from "assets/img/joe-kotz.jpg";
import logoSb from "assets/img/logo-sb.png";
// import anon from 'assets/img/clem-onojegaw.jpg';
// import logoiStretchPlus from 'assets/img/logo-istretchplus.png';

// MUI
const Container = dynamic(() => import("@mui/material/Container"), {
  loading: () => null,
});

// MUI Icons
const ChevronLeft = dynamic(() => import("@mui/icons-material/ChevronLeft"), {
  loading: () => null,
});
const ChevronRight = dynamic(() => import("@mui/icons-material/ChevronRight"), {
  loading: () => null,
});

// Parts
const ComplexReviewCard = dynamic(() => import("examples/ComplexReviewCard"), {
  loading: () => null,
});

function ReviewsSlider() {
  SwiperCore.use([Autoplay, Navigation]);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",
    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  return (
    <MDBox py={8} position="relative">
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current!, // Assert non-null
          nextEl: navigationNextRef.current!, // Assert non-null
        }}
        autoplay={{ delay: 5000 }}
        speed={800}
        spaceBetween={0}
        slidesPerView={1}
        loop
      >
        <SwiperSlide>
          <Container>
            <ComplexReviewCard
              color="dark"
              image={joeKotz.src}
              title="Excellent dev. On top of it!"
              review="Chris is great on our team and in our agile environment. He took the vision and made it a reality. He gets it done."
              author={{
                logo: logoSb.src,
                name: "Joe Kotz",
                role: "CEO - SoundBooth",
              }}
            />
          </Container>
        </SwiperSlide>
        {/* <SwiperSlide>
          <Container>
            <ComplexReviewCard
              color="dark"
              image={anon.src}
              title="Awesome work! Excellent result!"
              review="Chris organized the project from the get-go. Dev'd the entire project
               how we want it. Got our web app out there."
              author={{
                logo: logoiStretchPlus.src,
                name: 'Anon',
                role: 'CEO - Anon',
              }}
            />
          </Container>
          </SwiperSlide> */}
        <MDTypography
          variant="h2"
          color="dark"
          sx={{
            ...navigationStyles,
            left: 0,
          }}
          ref={navigationPrevRef}
        >
          <ChevronLeft />
        </MDTypography>
        <MDTypography
          variant="h2"
          color="dark"
          sx={{
            ...navigationStyles,
            right: 0,
          }}
          ref={navigationNextRef}
        >
          <ChevronRight />
        </MDTypography>
      </Swiper>
    </MDBox>
  );
}

export default ReviewsSlider;
